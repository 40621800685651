import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import { Button, Column, Layout, Row, Text } from '../components'
import { TEXTS } from '../constants'
import importantIcon from '../images/icons/important.svg'
import styles from './order.module.css'

export default () => {
    const { text, order, goldStar, decree, expedition } = useStaticQuery(graphql`
    {
      text: contentfulText(tid: {eq: "order-main-text"}) {
        title
        content {
          json
        }
      },
      order: file(relativePath: { eq: "order/order-of-lenin.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      goldStar: file(relativePath: { eq: "order/gold-star-medal.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      decree: file(relativePath: { eq: "order/decree.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      expedition: file(relativePath: { eq: "order/expedition.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
    }`)
    return (
        <Layout header={text.title} pageTitle={text.title}>
            <h3>{TEXTS.orderSubheader}</h3>
            <Row className={styles.info}>
                <Column width={25}>
                    <Img fluid={goldStar.childImageSharp.fluid} alt={TEXTS.goldStar} className={styles.goldStar} />
                    <Img fluid={order.childImageSharp.fluid} alt={TEXTS.order} />
                </Column>
                <Column width={75}>
                    <Img fluid={decree.childImageSharp.fluid} alt={TEXTS.decree} className={styles.decree} />
                    <Text content={text.content.json} />
                </Column>
            </Row>
            <Row>
                <Column width={25}>
                    <p className={styles.capture}>{TEXTS.orderCapture}</p>
                    <Button to="/important/order" text={TEXTS.important} icon={importantIcon} />
                </Column>
                <Column width={75}>
                    <Img fluid={expedition.childImageSharp.fluid} />
                </Column>
            </Row>
        </Layout>
    )
}
